<template>
<div v-if="reloadForm == false">
    <div v-if="itemCreate">
        <Breadcrumb :home="home" :model="items" class="mb-3"/>
        <div class="grid">
            <div class="col-12">
                <div class="card">
                    <Toast/>
                    <Error :errors="errors"/>

                    <Message severity="info" :closable="false">
                        <div>Project Code: {{this.itemCreate?.project_code}}</div>
                        <div>Project Name: {{this.itemCreate?.project_name}}</div>
                    </Message>

                </div>
            </div>
        </div>
        <TabMenu :model="items2" :activeIndex="activeIndex"></TabMenu>
        <div class="my-4">
            <router-view />
        </div>
    </div>
    <div v-else>
        <h3 class="text-center">Project Not Found</h3>
    </div>
</div>
<div v-else>
    <SkeletonComponent />
</div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import Error from '../../../Error.vue';
import SkeletonComponent from '../../../SkeletonComponent.vue';

export default {
    components: {
		'Error': Error,
		'SkeletonComponent': SkeletonComponent,
	},
    data() {
        return {
            // breadcrumb
            home: {icon: 'pi pi-home', to: '/'},
            items: [
                {label: 'Project', to: '/project'},
                {label: 'Detail'},
            ],
            items2: [
                {label: 'Salesman Calculation', command:() => {
                     this.$router.replace({ name: "project-detail-salesman-calculation", params: {id:this.itemCreate?.project_id} })
                }},
                {label: 'Delivery Calculation', command:() => {
                     this.$router.replace({ name: "project-detail-delivery-calculation", params: {id:this.itemCreate?.project_id} })
                }},
                {label: 'Territory Mapping', command:() => {
                     this.$router.replace({ name: "project-detail-territory-mapping", params: {id:this.itemCreate?.project_id} })
                }},
            ],
            activeIndex: 0,

            // loading
            loading: false,
            reloadForm: false,

            project_id: null,

            itemCreate: {},
        }
    },
    created() {
        this.project_id = this.$route.params.id;
        this.getItemById(this.project_id);
    },
    mounted() {
        this.$store.commit('setErrors', {});
    },
    watch: {
    },
    computed:{
        ...mapGetters(['errors']),
    },
    methods: {
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        getItemById(id) {
            this.reloadForm = true;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/project/check',
                params: {
                    "project_id": id,
                }
            })
            .then(res => {
                this.reloadForm = false;

                if (res) {

                    this.$store.commit('setErrors', {});
                    this.itemCreate = res.data.data;
                }
            }, (this))
            .catch((err) => {
                this.itemCreate = null;
                console.log(err);
                this.reloadForm = false;
            });
        }
    }
}
</script>